import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>Our story</h2>
        <p>It all starts with a dream</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>Dvije kućice, dvije srećice</h3>
          <p>
          Sve je počelo još 2010. kad smo, prvi put, moj sin i ja s mojim tastom došli na njegovo imanje od 11.000 kvadrata i pekli kobasice, držeći ih na štapiću uz logorsku vatru. Kad mi je tast rekao da je na mjestu na kojem se nalazimo nekoć davno bila kućica njegova nona u kojoj bi se odmarao za vrijeme radova u vinogradu i ispaše, očaran mirom i tišinom, sinula mi je ideja da napravimo neku kolibu i roštilj za povremeno uživanje. Već iduće godine kupili smo i samostalno postavili prvu kućicu. Majstor Bepo sazidao nam je kameni kamin i popločio terasu kamenim škrilama, kupili smo masivni hrastov stol s klupama, i tada nam se činilo da je to - to, prepričava nam vlasnik Robinson House Istra, drvene kuće namijenjene robinzonskom smještaju u blizini Vižinade u Istri.
          </p>

          <p>
            Vestibulum ultrices risus velit, sit amet blandit massa auctor sit
            amet. Sed eu lectus sem. Phasellus in odio at ipsum porttitor mollis
            id vel diam. Praesent sit amet posuere risus, eu faucibus lectus.
            Vivamus ex ligula, tempus pulvinar ipsum in, auctor porta quam.
            Proin nec dui cursus, posuere dui eget interdum. Fusce lectus magna,
            sagittis at facilisis vitae, pellentesque at etiam. Quisque posuere
            leo quis sem commodo, vel scelerisque nisi scelerisque. Suspendisse
            id quam vel tortor tincidunt suscipit. Nullam auctor orci eu dolor
            consectetur, interdum ullamcorper ante tincidunt. Mauris felis nec
            felis elementum varius.
          </p>

          <hr />

          <h4>Feugiat aliquam</h4>
          <p>
            Nam sapien ante, varius in pulvinar vitae, rhoncus id massa. Donec
            varius ex in mauris ornare, eget euismod urna egestas. Etiam lacinia
            tempor ipsum, sodales porttitor justo. Aliquam dolor quam, semper in
            tortor eu, volutpat efficitur quam. Fusce nec fermentum nisl. Aenean
            erat diam, tempus aliquet erat.
          </p>

          <p>
            Etiam iaculis nulla ipsum, et pharetra libero rhoncus ut. Phasellus
            rutrum cursus velit, eget condimentum nunc blandit vel. In at
            pulvinar lectus. Morbi diam ante, vulputate et imperdiet eget,
            fermentum non dolor. Ut eleifend sagittis tincidunt. Sed viverra
            commodo mi, ac rhoncus justo. Duis neque ligula, elementum ut enim
            vel, posuere finibus justo. Vivamus facilisis maximus nibh quis
            pulvinar. Quisque hendrerit in ipsum id tellus facilisis fermentum.
            Proin mauris dui, at vestibulum sit amet, auctor bibendum neque.
          </p>
          <h3>In press</h3>
          <ul>
              <li>Jutarnji, Aug 17, 2021 - <a href="https://www.jutarnji.hr/domidizajn/interijeri/dvije-kucice-dvije-srecice-u-blizini-vizinade-pronasli-smo-mjesto-koje-ocarava-mirom-i-tisinom-15095407">ROBINSON HOUSE ISTRA - 
Dvije kućice, dvije srećice</a></li>
          </ul>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
